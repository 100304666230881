/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import "./index.css"
import React from "react"
import SEO from "../components/SEO"
import logo from "../img/logo.png"

export default () => {
  return (
    <React.Fragment>
      <SEO />
      <div className="main">
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100vh;
            flex-direction: column;
          `}
          onKeyDown={() => {}}
          onClick={() => {
            window.location = "/"
          }}
          role="none"
        >
          <img
            css={css`
              width: 150px;
            `}
            src={logo}
            alt="sasatanikikaku logo"
          />
          <h2>只今準備中</h2>
        </div>
      </div>
    </React.Fragment>
  )
}
